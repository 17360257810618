import React, { ReactNode } from 'react';
import { createAppKit } from '@reown/appkit/react'
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { projectId, metadata, networks, socials, includeWalletIds, featuredWalletIds, wagmiAdapter } from './Web3ModalConfig';
import { sepolia } from 'viem/chains';

// Crear un cliente de React Query
const queryClient = new QueryClient();

const generalConfig = {
  defaultNetwork: sepolia,
  projectId,
  metadata,
  networks,
  // features: {
  //   email: true,
  //   socials,
  //   emailShowWallets: true,
  //   swaps: false,
  //   allWallets: false,
  // },
  // includeWalletIds,
  features:{
    email: true,
    socials
  }
}

// Create modal
createAppKit({
  adapters: [wagmiAdapter],
  ...generalConfig,
  allWallets: "HIDE",
  featuredWalletIds: [
    '87eecbca66faef32f044fc7c66090fc668efb02e2d17dda7bf095d51dff76659', // crossmint
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
  ],
  includeWalletIds: [
    '87eecbca66faef32f044fc7c66090fc668efb02e2d17dda7bf095d51dff76659', // crossmint
    'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
  ]
})

interface Web3ModalProviderProps {
  children: ReactNode;
}

export function Web3ModalProvider({ children }: Web3ModalProviderProps) {
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  );
}