import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { AppKitNetwork, mainnet, sepolia } from '@reown/appkit/networks';
import { createAppKit, FeaturesSocials  } from '@reown/appkit/react';
import i18next from 'i18next';

// Validar projectId
export const projectId = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '';
if (!projectId) throw new Error('Project ID is undefined');

// Metadata
export const metadata = {
  name: process.env.REACT_APP_NAME || 'My App',
  description: (i18next && i18next.isInitialized && i18next.t('auth.messages.signature') ? i18next.t('auth.messages.signature') : ''),
  url: window.location.origin,
  icons: ['https://app.follovers.art/assets/images/logo.webp'],
};

// Redes soportadas
// const networks: [AppKitNetwork, ...AppKitNetwork[]] = [sepolia, mainnet];
export const networks = [sepolia] as [AppKitNetwork, ...AppKitNetwork[]];

export const wagmiAdapter = new WagmiAdapter({
  projectId,
  networks,
});

// Definir socials como un array de FeaturesSocials
export const socials: FeaturesSocials[] = ['google', 'x', 'discord', 'apple', 'facebook'];

export const includeWalletIds: string[] = [
  '87eecbca66faef32f044fc7c66090fc668efb02e2d17dda7bf095d51dff76659', // crossmint
  'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
];

export const featuredWalletIds: string[] = [
  '87eecbca66faef32f044fc7c66090fc668efb02e2d17dda7bf095d51dff76659', // crossmint
  'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96', // metamask
];

export const config = wagmiAdapter.wagmiConfig;