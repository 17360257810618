import auth from './auth/auth.api';
import collections from './collections/collections.api';
import tokens from './tokens/tokens.api';
import sliders from './sliders/sliders.api';
import influencers from './influencers/influencers.api';
import users from './users/users.api';
import socialPlatforms from './social_platforms/social_platforms.api';
import categories from './categories/categories.api';
import stats from './stats/stats.api';
import blogs from './blogs/blogs.api';
import suscribers from './suscribers/suscribers.api';
import prices from './coingecko/prices.api';

const fvApi = {
    auth,
    collections,
    tokens,
    sliders,
    influencers,
    users,
    socialPlatforms,
    categories,
    stats,
    blogs,
    suscribers,
    prices
};

export default fvApi;