"use client";

import { AuthUserParams } from "api/auth/types";
import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import { Chain } from "viem";
import { useAccount, useDisconnect, useSignMessage } from "wagmi";
import { login, logout, setAuthentication, setUser } from 'store/auth/auth.slice';
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { User } from "api/users/types";
import { useAppKitAccount, useAppKitEvents } from "@reown/appkit/react";
import { verifyMessage } from '@wagmi/core'
import { WagmiConfig } from "./WagmiConfig";
import { fetchEthPriceUsd } from "store/coingecko/prices.slice";

interface AppContextType {
  authUser: User | null;
  address: string | undefined;
  chain: Chain | undefined;
  isWalletConnected: boolean;
  isWalletConnecting: boolean;
  isAuthenticated: boolean;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  error: string | null;
  setError: React.Dispatch<React.SetStateAction<string | null>>;
  logoutApp: () => Promise<void>;
}

const AppContext = createContext<AppContextType | undefined>(
  undefined,
);

export function AppContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const {isAuthenticated, user} = useSelector((state: RootState) => state.auth);
  const { chain, address, isConnected, status, isConnecting } = useAccount();
  const { disconnectAsync } = useDisconnect()
  const { signMessageAsync } = useSignMessage();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const isWalletConnected =
    isConnected &&
    !isConnecting &&
    status === "connected" &&
    Boolean(chain) &&
    Boolean(address) &&
    isLoggingOut === false;

  //// @debug
  // console.log('AppContextProvider address, address2, isWalletConnected, isAuthenticated, isLoggingOut, statusWC, chain, chainId, isConnected', address, address2, isWalletConnected, isAuthenticated, isLoggingOut, status, chain, chainId, isConnected);
  // console.log('AppContextProvider caipAddress, isConnected2', caipAddress, isConnected2);

  const isWalletConnecting =
    isConnecting || ["reconnecting", "connecting"].includes(status);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const logoutApp = useCallback(async () => {
    setIsLoggingOut(true);
    
    // @debug
    // console.log('AppContextProvider logoutApp START loading, isWalletConnecting, isWalletConnected, isAuthenticated, isLoggingOut, statusWC', loading, isWalletConnecting, isWalletConnected, isAuthenticated, isLoggingOut, status);

    // disconnect();
    await disconnectAsync();
    await dispatch(logout());
    setIsLoggingOut(false);

    // @debug
    // console.log('AppContextProvider logoutApp ENDED loading, isWalletConnecting, isWalletConnected, isAuthenticated, isLoggingOut', loading, isWalletConnecting, isWalletConnected, isAuthenticated, isLoggingOut);
  }, [disconnectAsync, dispatch]);

  // @debug
  // console.log('AppContextProvider loading, isWalletConnecting, isWalletConnected, isAuthenticated, isLoggingOut', loading, isWalletConnecting, isWalletConnected, isAuthenticated, isLoggingOut);

  const authenticate = useCallback(async () => {
    setLoading(true);

    // @debug
    // console.log('AppContextProvider authenticate was called chain, address', chain, address);

    if (!isWalletConnected || isAuthenticated){
      setLoading(false);
      return;
    }

    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const user = JSON.parse(storedUser);

      // @debug
      // console.log('AppContextProvider user exists. SetAuth will be TRUE', user);

      dispatch(setAuthentication(true));
      dispatch(setUser(user));
    } else{
      const nonce: string = uuidv4();
      const addressFormatted: string = (address as string).toLowerCase();
      let message:string = t('auth.messages.signature').replace('{address}', addressFormatted).replace('{nonce}', nonce);
      try {
        const signature = await signMessageAsync({ message });
        const isValid = await verifyMessage(WagmiConfig, {
          address,
          message,
          signature,
        });

        //// @debug
        // console.log('isValid', isValid);

        if(isValid){
          const params: AuthUserParams = {
            user_fields: 'wallets,token',
            address: addressFormatted,
            signature: signature,
            nonce: nonce,
            message: message,
            chain: chain?.name,
            email: localStorage.getItem('@appkit-wallet/EMAIL') || ''
          };
          await dispatch(login(params));
        }else{
          throw new Error("Invalid signature");
        }
      } catch (error) {
        // @debug
        // console.error('AppContextProvider Signature failed', error);

        enqueueSnackbar(t('auth.errors.signature_failed'), { variant: 'warning' });

        logoutApp();
      }
    }
    setLoading(false);
  }, [isWalletConnected, isAuthenticated, address, chain, signMessageAsync, enqueueSnackbar, t, dispatch, logoutApp]);

  useEffect(() => {
    if (isLoggingOut) return;

    setLoading(true);
    if (isWalletConnected && !isAuthenticated) {
      authenticate();
    // } else if (!isWalletConnected && isAuthenticated) {
    //   dispatch(logout());
    //   setLoading(false);
    // }else{
    }
    setLoading(false);
  }, [isWalletConnected, isAuthenticated, authenticate, isLoggingOut, dispatch]);

  useEffect(() => {
    // dispatch on load.
    dispatch(fetchEthPriceUsd());

    // interval
    const interval = setInterval(() => {
      dispatch(fetchEthPriceUsd());
    }, 60000); // 60,000 ms = 1 minuto

    // clear interval.
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <AppContext.Provider
      value={{
        isWalletConnected,
        isWalletConnecting,
        isAuthenticated,
        authUser: user,
        address,
        chain,
        loading,
        setLoading,
        error,
        setError,
        logoutApp
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(
      "useAppContext must be used within a AppContextProvider",
    );
  }

  return context;
}