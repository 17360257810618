import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'

import { fetchFeaturedCollections } from '../../store/collections/collections.slice';
import { AppDispatch, RootState } from '../../store/store';
import { Collection } from '../../api/collections/types';
import { OrderBy } from '../../api/collections/enums';
import { FiltersYesNo } from '../../enums/filters';
import LazyImage from '../lazy-image';
import { getNextStage } from 'utils/helpers/collectionHelper';
import { formatSalePrice } from 'utils/helpers/formatHelper';
import { useTranslation } from 'react-i18next';
import EthSalePriceToUsd from 'components/eth-sale-price-to-usd';

export default function FeaturedCollection() {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const { featuredCollections, isLoadingFeatured, errorMessageFeatured } = useSelector((state: RootState) => state.collections);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        dispatch(fetchFeaturedCollections({featured: FiltersYesNo.YES, order_by: OrderBy.RANDOM, collection_fields: 'owner,stages,contracts'}));
    }, [dispatch]);

    const collection = featuredCollections?.data?.[0] as Collection;
    const stage = getNextStage(collection?.stages || [], timeZone);
    const contract = collection?.contracts && collection?.contracts.length > 0 ? collection.contracts[0] : null;

    if (isLoadingFeatured) {
        return (
            <div className="container mt-100 mt-60">
                <div className="row align-items-end mb-4 pb-2">
                    <div className="col-md-8">
                        <div className="section-title">
                            <h4 className="title mb-2">{t('collection.featured.labels.title')}</h4>
                            <p className="text-muted mb-0">{t('collection.featured.labels.subtitle')}</p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="sticky-bar placeholder-glow">
                            <div className="placeholder w-100" style={{ height: '736px' }}></div>
                        </div>
                    </div>

                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="ms-lg-5">
                            <div className="title-heading placeholder-glow">
                                <h4 className="placeholder col-8 mb-0" aria-hidden="true">Loading...</h4>
                            </div>

                            <div className="row">
                                <div className="col-md-12 mt-4 pt-2 placeholder-glow">
                                    <h6 className="placeholder col-8" aria-hidden="true">Loading...</h6>
                                </div>

                                <div className="col-12 mt-4 pt-2 placeholder-glow">
                                    <span className="placeholder col-4"></span>
                                </div>
                            </div>

                            <div className="row mt-4 pt-2">
                                <div className="col-12">
                                    <ul className="nav nav-tabs border-bottom placeholder-glow">
                                        <li className="nav-item">
                                            <span className="placeholder col-2"></span>
                                        </li>
                                    </ul>
                    
                                    <div className="tab-content mt-4 pt-2 placeholder-glow">
                                        <p className="placeholder col-12" style={{ height: '100px' }}></p>
                                        <h6 className="placeholder col-2" aria-hidden="true">Loading...</h6>
                    
                                        <div className="creators creator-primary d-flex align-items-center">
                                            <div className="position-relative placeholder-glow">
                                                <div className="placeholder rounded-pill avatar avatar-md-sm"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          );
    }

    if (errorMessageFeatured) {
        return <div>Error: {errorMessageFeatured}</div>;
    }


  return collection && (
    <div className="container mt-100 mt-60">
        <div className="row align-items-end mb-4 pb-2">
            <div className="col-md-8">
                <div className="section-title">
                    <h4 className="title mb-2">{t('collection.featured.labels.title')}</h4>
                    <p className="text-muted mb-0">{t('collection.featured.labels.subtitle')}</p>
                </div>
            </div>

            <div className="col-md-4">
                <div className="text-end d-md-block d-none">
                    <Link to={`/collection/${collection.slug}`} className="btn btn-link primary text-dark">{t('common.buttons.see_more.label')} <i className="uil uil-arrow-right"></i></Link>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
                <div className="sticky-bar">
                    <LazyImage src={collection.featured_image_permalink ?? collection.logo_permalink} className="img-fluid rounded-md shadow" alt={collection.slug} height='546px'/>
                </div>
            </div>

            <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="ms-lg-5">
                    <div className="title-heading">
                        <h4 className="h3 fw-bold mb-0">{collection.name}</h4>
                    </div>

                    
                    <div className="row">
                        {contract?.is_soldout ? (
                            <div className="col-md-12 mt-4 pt-2">
                                <h4 className="mb-0">{t('collection.contracts.labels.soldout')}</h4>
                            </div>
                        ) : (
                            <>
                                {stage && (
                                <div className="col-md-12 mt-4 mb-2 pt-2">
                                    <h6>{stage.name}</h6>
                                    <h4 className="mb-0">{formatSalePrice(stage.sale_price)}
                                    {/* <EthSalePriceToUsd sale_price={stage.sale_price} /> */}
                                    </h4>
                                </div>
                                )}

                                {contract && (
                                <div className="col-md-12">
                                    <small className="text-dark fw-bold">{t('collection.contracts.labels.total_minted_out_of_total_supply').replace(':total_minted', contract.total_minted.toString()).replace(':total_supply', contract.total_supply.toString())}</small>
                                </div>
                                )}
                            </>
                        )}
                        <div className="col-12 mt-4 pt-2">
                            <Link to={`/collection/${collection.slug}`} className="btn btn-l btn-pills btn-primary"><i className="mdi mdi-eye fs-5 me-2"></i> {t('common.buttons.view_more.label')}</Link>
                        </div>
                    </div>
                    

                    <div className="row mt-4 pt-2">
                        <div className="col-12">
                            <ul className="nav nav-tabs border-bottom">
                                <li className="nav-item">
                                    <button className={`nav-link active`}>{t('collection.fields.details')}</button>
                                </li>
                            </ul>
    
                            <div className="tab-content mt-4 pt-2">
                                <div className="tab-pane fade show active">
                                    <p className="text-muted" style={{ whiteSpace: 'pre-wrap' }}>{collection.description}</p>
                                    <h6>{t('collection.fields.owner')}</h6>
    
                                    <div className="creators creator-primary d-flex align-items-center">
                                        <div className="position-relative">
                                            <LazyImage src={collection.owner.profile_photo_url} className="avatar avatar-md-sm shadow-md rounded-pill" alt={collection.owner.username} height='54px'/>
                                        </div>
    
                                        <div className="ms-3">
                                            <h6 className="mb-0"><Link to={`/profile/${collection.owner.username}`} className="text-dark name">{collection.owner.name}</Link></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
