import React, { useState } from "react";
import { useDisconnect } from "wagmi";
import { Button, Spinner } from "react-bootstrap";
import { useAppKit } from '@reown/appkit/react'
import { useAppContext } from "context/AppContextProvider";
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
}

const ConnectButton: React.FC<Props> = ({className}) =>{
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { open } = useAppKit();
  const { disconnect } = useDisconnect();
  const {
    isWalletConnecting,
    isWalletConnected,
    isAuthenticated,
  } = useAppContext();

  const label = isWalletConnected ? t('common.buttons.disconnect.label') : (isWalletConnecting ? t('common.buttons.connect.loading') : t('common.buttons.connect.label'));

  async function openModal() {
    setLoading(true);
    await open();
    setLoading(false);
  }

  function onClick() {
    if (!isWalletConnected) openModal();
    else disconnect();
  }

  return !isAuthenticated && (
    <Button className={`${className} reverse`} onClick={onClick} disabled={loading || isWalletConnecting}>
      {loading ? <Spinner color="white" /> : label}
    </Button>
  );
}

export default ConnectButton;