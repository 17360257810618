import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { RootState } from '../store/store';

import { useWalletInfo } from '@reown/appkit/react'
import ConnectButton from './connect-button';
import AddressDisplay from './address-display';
import { useAppContext } from 'context/AppContextProvider';
import { shortenText } from 'utils/helpers/formatHelper';

const NavbarProfile = ()=> {
    const { walletInfo } = useWalletInfo()
    const { t } = useTranslation();
    const { user } = useSelector((state: RootState) => state.auth);
    const { isAuthenticated, logoutApp  } = useAppContext();
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement | null>(null);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && event.target instanceof Node && !menuRef.current.contains(event.target)) {
          setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
        document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    
    //// @debug
    // console.log('NavbarProfile isAuthenticated, user', isAuthenticated, user);
    // console.log('NavbarProfile walletInfo', walletInfo);

    return (
    <>
        {isAuthenticated && user ? (
            <div className="dropdown dropdown-primary" ref={menuRef}>
                <button type="button" className="btn btn-pills dropdown-toggle p-0" onClick={toggleMenu}><img src={(user.profile_photo_url ?? walletInfo?.icon ?? `https://ui-avatars.com/api/?name=${user.username}`)} className="rounded-pill avatar avatar-sm-sm" alt={user.username}/></button>
                {isOpen && (
                <div className="dropdown-menu dd-menu dropdown-menu-end bg-white shadow border-0 mt-3 pb-3 pt-0 overflow-hidden rounded d-block  end-0" style={{width:'200px'}}>
                    <div className="position-relative">
                        <div className="pt-5 pb-3 bg-gradient-primary"></div>
                        <div className="px-3">
                            <div className="d-flex align-items-end mt-n4">
                                <img src={(user.profile_photo_url ?? walletInfo?.icon ?? `https://ui-avatars.com/api/?name=${user.username}`)} className="rounded-pill avatar avatar-md-sm img-thumbnail shadow-md" alt={user.username}/>
                                <h6 className="text-dark fw-bold mb-0 ms-1">{shortenText(user.name)}</h6>
                            </div>
                            {user.wallets && user.wallets.length > 0 && (
                                <AddressDisplay address={user.wallets[0].wallet_address} />
                            )}
                        </div>
                    </div>
                    <div className="mt-2">
                        <Link className="dropdown-item small fw-semibold text-dark d-flex align-items-center" to={`/profile/${user.username}`}><span className="mb-0 d-inline-block me-1"><i className="uil uil-user align-middle h6 mb-0 me-1"></i></span> {t('header.profile.labels.profile')}</Link>
                        <Link className="dropdown-item small fw-semibold text-dark d-flex align-items-center" to="/account/settings"><span className="mb-0 d-inline-block me-1"><i className="uil uil-cog align-middle h6 mb-0 me-1"></i></span> {t('header.profile.labels.settings')}</Link>
                        <div className="dropdown-divider border-top"></div>
                        <button className="dropdown-item small fw-semibold text-dark d-flex align-items-center" onClick={logoutApp}><span className="mb-0 d-inline-block me-1"><i className="uil uil-sign-out-alt align-middle h6 mb-0 me-1"></i></span> {t('header.profile.labels.logout')}</button>
                    </div>
                </div>
                )}
            </div>
        ) : (
            <ConnectButton />
        )}
    </>
    )
};

export default NavbarProfile;