import React,{useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate, Link  } from 'react-router-dom';
import { AppDispatch, RootState } from '../../store/store';
import { fetchAll, getByIdentifier, clearToken } from '../../store/tokens/tokens.slice';
import Footer from '../../components/footer';
import Navbar from 'components/navbar';
import ExpandableText from 'components/expandable-text';
import { shortenAddress, shortenText } from 'utils/helpers/formatHelper';
import { FetchParams, Token, Trait } from 'api/tokens/types';
import ItemTrait from 'components/collection/items/item-trait';
import { OrderBy } from 'api/tokens/enums';
import CollectionItemCard from 'components/collection/items/collection-item-card';
import { Helmet } from 'react-helmet-async';
import LazyImage from 'components/lazy-image';
import { useTranslation } from 'react-i18next';
import ShareButton from 'components/share-button';
import ShareMarketplace from 'components/share-marketplace';

const ItemDetail = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { token, isLoadingT = true, tokens, isLoading = true, errorCodeT = null } = useSelector((state: RootState) => state.tokens);
    const { identifier, token_identifier } = useParams<{ identifier: string, token_identifier: string }>();
    let [activeTab, setActiveTab] = useState(1)
    
    useEffect(() => {
        if (identifier && token_identifier) {
            setActiveTab(1);
            dispatch(getByIdentifier({ collection_slug: identifier, identifier: token_identifier, params: {token_fields:'collection', collection_fields:'owner,contracts,token_standard'}}));
        }

        return () => {
            dispatch(clearToken());
        };
    }, [dispatch, identifier, token_identifier]);

    useEffect(() => {
        if (errorCodeT) {
            navigate('/404', { replace: true });
        }
    }, [errorCodeT, navigate]);

    useEffect(() => {
        if (token && token.collection) {
            const params: FetchParams = { 
                order_by: OrderBy.NAME,
                except_ids: token.id,
                top: 3
            };
            dispatch(fetchAll({collection_slug: token.collection.slug, params}));
        }
    }, [dispatch, token]);

    const contract = (token?.collection?.contracts && token?.collection?.contracts.length > 0 ? token?.collection?.contracts[0] : null);

    const currentUrl = `${window.location.origin}/collection/${token?.collection?.slug}/items/${token?.identifier_number}`;

    return (
        <>
        {token && (
            <Helmet>
                <title>{token.name} - {process.env.REACT_APP_NAME}</title>
                <meta content={token.name +' '+ token.collection?.name +' | '+ process.env.REACT_APP_NAME} property="og:title"/>
                <meta content="website" property="og:type"/>
                <meta content={token.display_image_permalink} property="og:image"/>
                <meta content={currentUrl} property="og:url"/>
                <meta content={token.description} property="og:description"/>

                <meta content="summary_large_image" name="twitter:card" />
                <meta content={token.name +' '+ token.collection?.name} name="twitter:title"/>
                <meta content={'Created by ' + token.collection?.owner.name} name="twitter:author"/>
                <meta content={token.display_image_permalink} name="twitter:image"/>
                <meta content={token.name} name="twitter:image:alt"/>
                <meta content={currentUrl + "/?utm_medium=organic&amp;utm_source=twitter&amp;utm_campaign=demo"} name="twitter:url"/>
                <meta content={'View on ' + process.env.REACT_APP_NAME} name="twitter:cta"/>
                <link href={currentUrl} rel="canonical"/>
                <meta content={token.description} name="description"/>

                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'WebSite',
                        name: process.env.REACT_APP_NAME,
                        url: window.location.origin
                    })}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'Organization',
                        name: process.env.REACT_APP_NAME,
                        url: window.location.origin,
                        logo: window.location.origin + '/assets/images/logo-light.webp',
                    })}
                </script>
                <script type="application/ld+json">
                    {JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'Product',
                        name: token.name,
                        image: token.display_image_permalink,
                        description: token.description,
                        brand: {
                            '@type': 'Brand',
                            name: token.collection?.name,
                        },
                        creator: {
                            '@type': 'Person',
                            name: token.collection?.owner.name,
                        },
                        url: currentUrl,
                    })}
                </script>
            </Helmet>
        )}
        <Navbar navlight={false}/>
        <section className="bg-item-detail d-table w-100">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        {isLoadingT ? (
                        <div className="sticky-bar placeholder-glow">
                            <div className="placeholder w-100" style={{ height: '736px' }}></div>
                        </div>
                        ) : (
                        <div className="sticky-bar">
                            <LazyImage src={token?.display_image_permalink ?? ''} className="img-fluid rounded-md shadow" alt={token?.name ?? ''} height='736px'/>
                        </div>
                        )}
                    </div>

                    <div className="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                        <div className="ms-lg-5">
                            {isLoadingT ? (
                            <div className="title-heading placeholder-glow">
                                <h6 className="placeholder text-muted fw-normal mb-3" aria-hidden="true">Loading...</h6>
                                <br />
                                <h4 className="placeholder h3 fw-bold mb-0" aria-hidden="true">Loading...</h4>
                            </div>
                            ) : (
                            <div className="title-heading">
                                <Link to={`/collection/${identifier}`}>
                                    <h6 className="text-muted fw-normal mb-3">{token?.collection?.name}</h6>
                                </Link>
                                <h4 className="h3 fw-bold mb-0">{token?.name}</h4>
                            </div>
                            )}

                            <div className="row mt-4 pt-2">
                                <div className="col-12">
                                    <ul className="nav nav-tabs border-bottom">
                                        <li className="nav-item">
                                            <button className={`nav-link ${activeTab === 1 ? 'active' : ''}`} onClick={()=>setActiveTab(1)}>{t('collection.item.tabs.1.title')}</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className={`nav-link ${activeTab === 2 ? 'active' : ''}`} onClick={()=>setActiveTab(2)}>{t('collection.item.tabs.2.title')}</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className={`nav-link ${activeTab === 3 ? 'active' : ''}`} onClick={()=>setActiveTab(3)}>{t('collection.item.tabs.3.title')}</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className={`nav-link ${activeTab === 4 ? 'active' : ''}`} onClick={()=>setActiveTab(4)}>{t('collection.item.tabs.4.title')}</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content mt-4 pt-2">
                                        {activeTab === 1 && (
                                            <div className="tab-pane fade show active">
                                                {isLoadingT ? (
                                                <p className="placeholder text-muted fw-normal mb-3" aria-hidden="true">
                                                    Loading...
                                                </p>
                                                ) : token?.description ? (
                                                <ExpandableText text={token?.description} limit={100} initiallyExpanded={true} />
                                                ) : (
                                                <p className="text-muted" dangerouslySetInnerHTML={{ __html: t('collection.item.tabs.1.empty_data') }} />
                                                )}
                                            </div>
                                        )}
                                        {activeTab === 2 && (
                                            <div className="tab-pane fade show active">
                                                {isLoadingT ? (
                                                <p className="placeholder text-muted fw-normal mb-3" aria-hidden="true">
                                                    Loading...
                                                </p>
                                                ) : token && token.traits && token.traits.length > 0 ? (
                                                    <div className="row">
                                                        {token.traits.map((trait:Trait, index:number)=>{
                                                            return(
                                                            <div key={index} className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mb-4">
                                                                <ItemTrait trait={trait} />
                                                            </div>
                                                            )
                                                        })}
                                                    </div>
                                                ) : (
                                                    <p className="text-muted" dangerouslySetInnerHTML={{ __html: t('collection.item.tabs.2.empty_data') }} />
                                                )}
                                            </div>
                                        )}
                                        {activeTab === 3 && (
                                            <div className="tab-pane fade show active">
                                                {isLoadingT ? (
                                                <p className="placeholder text-muted fw-normal mb-3" aria-hidden="true">
                                                    Loading...
                                                </p>
                                                ) : (
                                                <ExpandableText text={token?.collection?.description} limit={100} initiallyExpanded={true} />
                                                )}
                                            </div>
                                        )}
                                        {activeTab === 4 && (
                                            <div className="tab-pane fade show active">
                                                <div className="row mb-2">
                                                    <div className="col">
                                                    {t('collection.item.tabs.4.fields.contract_address')}
                                                    </div>
                                                    <div className="col text-end">
                                                        {isLoadingT ? (
                                                        <span className="placeholder text-muted" aria-hidden="true">Loading...</span>
                                                        ) : contract && (
                                                        <a href={contract.chain?.explorer_url + contract.address} className="text-primary" target="_blank" rel="noopener noreferrer">
                                                            {shortenAddress(contract.address || '')}
                                                        </a>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col">
                                                    {t('collection.item.tabs.4.fields.token_id')}
                                                    </div>
                                                    <div className="col text-end">
                                                        {isLoadingT ? (
                                                        <span className="placeholder text-muted" aria-hidden="true">Loading...</span>
                                                        ) : (
                                                        <span className="text-muted">{shortenText(token?.identifier_number || '')}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col">
                                                        {t('collection.item.tabs.4.fields.token_standard')}
                                                    </div>
                                                    <div className="col text-end">
                                                        {isLoadingT ? (
                                                        <span className="placeholder text-muted" aria-hidden="true">Loading...</span>
                                                        ) : contract && (
                                                        <span className="text-muted">{contract.token_standard?.name}</span>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row mb-2">
                                                    <div className="col">
                                                    {t('collection.item.tabs.4.fields.chain')}
                                                    </div>
                                                    <div className="col text-end">
                                                        {isLoadingT ? (
                                                        <span className="placeholder text-muted" aria-hidden="true">Loading...</span>
                                                        ) : contract && (
                                                        <span className="text-muted">{contract.chain?.name}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            {!isLoadingT && contract && token && (
                            <div className="d-flex justify-content-center mt-4 mb-5">
                                <ShareButton title={token.name} />
                                <ShareMarketplace contract={contract} token={token} />
                            </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section pt-0">
            <div className="container">
                {token && tokens && tokens.data.length > 0 && (
                <div className="row justify-content-center">
                    <div className="col">
                        <div className="section-title text-center mb-2">
                            <h4 className="title">{t('collection.item.more_items.title')}</h4>
                        </div>
                    </div>
                </div>
                )}
                <div className="row">
                    <div className="col-lg-12 col-md-12 mt-0 mt-sm-0 pt-2 pt-sm-0">
                        <div className="row row-cols-xl-3 row-cols-lg-2 row-cols-1">
                        {token && tokens && tokens.data.length > 0 && tokens.data.map((item: Token, index: number) => (
                            <CollectionItemCard key={index} token={item} collection={token.collection} />
                        ))}
                        {isLoading && Array.from({ length: 3 }).map((_, index) => (
                            <div className="col mt-4 pt-2" key={index}>
                                <div className="card nft-items nft-primary rounded-md shadow overflow-hidden mb-1 p-3">
                                    <div className="nft-image rounded-md position-relative overflow-hidden">
                                        <div className="placeholder-glow">
                                            <div className="placeholder" style={{ height: '229px', width: '100%' }}></div>
                                        </div>
                                    </div>
                                    <div className="card-body content position-relative p-0 mt-3">
                                        <div className="placeholder-glow">
                                            <div className="placeholder w-75 mb-2"></div>
                                            <div className="d-flex justify-content-between mt-2">
                                                <div className="placeholder w-25"></div>
                                                <div className="placeholder w-25"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                        {!isLoading && token && (
                            <div className="mt-5 text-center">
                                <Link to={`/collection/${identifier}`} className="back-button btn btn-dark">
                                {t('collection.item.back_collection')}
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        <div className="mt-100"></div>
        <Footer />
        </>
    )
};

export default ItemDetail;