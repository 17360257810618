import React from "react";
import { formatSalePriceToUsd } from "utils/helpers/formatHelper";
import { useTranslation } from "react-i18next";
import { Tooltip } from 'react-tooltip';
import { RootState } from "store/store";
import { useSelector } from "react-redux";

interface Props {
  sale_price: any;
}

const EthSalePriceToUsd: React.FC<Props> = ({ sale_price }) => {
  const { t } = useTranslation();
  const {ethPriceUsd, isLoading} = useSelector((state: RootState) => state.prices);

  return (
    <>
    {isLoading ? (
        <small className="placeholder-glow h6 ms-3">
            <span className="placeholder col-1"></span>
        </small>
    ) : (
        <>
        <small className="h6 text-muted ms-3">
        {formatSalePriceToUsd(sale_price, ethPriceUsd)}
        <i className="uil uil-info-circle cursor-pointer ms-2"
            data-tooltip-id='ethPriceUsd'
            data-tooltip-content={t('collection.fields.eth_price_usd_tooltip')}>&nbsp;</i>
        </small>
        <Tooltip id='ethPriceUsd' className="h6" />
        </>
    )}
    </>
  );
};

export default EthSalePriceToUsd;