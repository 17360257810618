import React,{useState} from 'react'
import { Link } from 'react-router-dom'

import logo  from '../assets/images/logo-footer.webp';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useTranslation } from 'react-i18next';
import { Category } from '../api/categories/types';
import NewsletterForm from './newsletter/newsletter-form';

export default function Footer() {
    const { t } = useTranslation();
    const { categoriesMenuList } = useSelector((state: RootState) => state.categories);
    let [visible, setVisible] = useState(false)

    const toggleVisible = () => { 
        const scrolled = document.documentElement.scrollTop; 
        if (scrolled > 300){ 
          setVisible(true) 
        }  
        else if (scrolled <= 300){ 
          setVisible(false) 
        } 
      }; 
      
      const scrollToTop = () =>{ 
        window.scrollTo({ 
          top: 0,  
          behavior: 'smooth'
        }); 
      }; 
      
      window.addEventListener('scroll', toggleVisible); 
  return (
    <>
    <footer className="bg-footer footer-marquee">
        <div className="footer-top-border marquee-content"></div>
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="footer-py-60">
                        <div className="row">
                            <div className="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                                <Link to="/" className="logo-footer">
                                    <img src={logo} alt="fv" className="img-fluid" style={{maxWidth:'40%'}} />
                                </Link>
                                <p className="para-desc mb-0 mt-4">{t('footer.logo.caption')}</p>
                                <ul className="list-unstyled social-icon foot-social-icon mb-0 mt-4 ">
                                    <li className="list-inline-item lh-1"><Link to="#" className="rounded"><i className="uil uil-instagram"></i></Link></li>
                                    <li className="list-inline-item lh-1"><Link to="#" className="rounded"><i className="uil uil-tiktok"></i></Link></li>
                                    <li className="list-inline-item lh-1"><Link to="#" className="rounded"><i className="uil uil-linkedin"></i></Link></li>
                                </ul>
                                
                            </div>
                            
                            <div className="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">{t('footer.head.1.title')}</h5>
                                <ul className="list-unstyled footer-list mt-4">
                                <li><Link to={`/`} className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {t('header.menu.home')}</Link></li>
                                {categoriesMenuList && categoriesMenuList.data.map((category:Category, index:number)=>(
                                    <li key={`cfoot-${category.id}`} className="ms-0"><Link to={`/category/${category.slug}`} className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {category.name}</Link></li>
                                ))}
                                </ul>
                            </div>
                            
                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <h5 className="footer-head">{t('footer.head.2.title')}</h5>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><Link to="https://www.bagheadlabs.io" target='_blank' className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {t('footer.head.2.menu.1')}</Link></li>
                                    <li className='ms-0'><Link to="/blog" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {t('footer.head.2.menu.2')}</Link></li>
                                    <li className='ms-0'><Link to="#" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {t('footer.head.2.menu.3')}</Link></li>
                                    <li className='ms-0'><Link to="#" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {t('footer.head.2.menu.4')}</Link></li>
                                    <li className='ms-0'><Link to="mailto:hi@bagheadlabs.io" className="text-foot"><i className="uil uil-angle-right-b me-1"></i> {t('footer.head.2.menu.5')}</Link></li>
                                </ul>
                            </div>
        
                            <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <NewsletterForm/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="footer-py-30">
            <div className="footer-b1">
                <div className="footer-b2"></div>
                <div className="yellow">
                    <p className="mb-0 text-white">© {new Date().getFullYear()} {process.env.REACT_APP_NAME}. Design by <Link to="https://bagheadlabs.io" target="_blank" className="text-reset fw-bold">Bagheadlabs</Link>.</p>
                </div>
            </div>
        </div>
    </footer>
    <Link to="#" id="back-to-top" onClick={()=>scrollToTop()} className="back-to-top btn-primary fs-5" style={{display: visible ? 'inline' : 'none'}}><i className="uil uil-arrow-up"></i></Link>
    </>
  )
}
