import { getCacheItem, setCacheItem } from "utils/helpers/cacheHelper";

const CACHE_DURATION = 60000; // 60 segundos

const prices = {
  fetchEthPriceUsd: async (): Promise<number> => {
    const currentTime = Date.now();

    const cachedPrice = getCacheItem("ethPriceUsd");
    if (cachedPrice && currentTime - cachedPrice.timestamp < CACHE_DURATION) {
      return cachedPrice.value;
    }

    try {
      const response = await fetch(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
      );
      const data = await response.json();

      const ethPrice = parseFloat(data.ethereum.usd);

      setCacheItem("ethPriceUsd", {
        value: ethPrice,
        timestamp: currentTime,
      });

      return ethPrice;
    } catch (error) {
      console.error("Error fetching ETH price:", error);

      if (cachedPrice) {
        return cachedPrice.value;
      }

      throw new Error("Unable to fetch ETH price and no cached value available.");
    }
  }
};

export default prices;